<template>
    <v-dialog v-model="dialog.tabla_asignaciones" persistent max-width="90%" transition="fade-transition" :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-fade-transition>
            <v-card class="rounded-lg">
                <v-card-title class="pa-0 modal-title">
                    <v-toolbar flat class="rounded-t-lg">
                        <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                            <v-list-item class="pl-0 rounded-t-lg">
                                <v-list-item-avatar class="mr-2 rounded-lg" color="secondary" tile width="8" height="30" min-width="8"/>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="headline pb-0 mb-0">
                                        <v-toolbar-title class="pa-0 trenos-title-toolbar text-capitalize grey--text text--darken-1" style="line-height: 1.1;">Tabla Asignaciones</v-toolbar-title>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-medium">
                                        <span class="text-capitalize">Edicion</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card flat max-height="80vh" style="overflow: auto;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="py-0" >
                                <v-menu ref="menu" v-model="menu_mes" :close-on-content-click="false" right origin="top left" transition="scroll-x-transition" rounded="lg" max-width="250" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-toolbar flat>
                                            <v-btn icon class="mr-2 " :class="TemaDark?'primary':'secondary'" v-bind="attrs" v-on="on" style="margin-left: -8px;height: 42px;width: 42px;">
                                                <v-icon :color="TemaDark?'white':'primary'" class="icon_btn_picker" size="20">mdi-calendar-edit</v-icon>
                                            </v-btn>
                                            <v-list-item class="px-0">
                                                <v-list-item-content>
                                                    <v-list-item-title class="headline mb-0">
                                                        <v-toolbar-title class="pa-0 trenos-title-toolbar text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">
                                                            {{NombreTituloFecha}}
                                                        </v-toolbar-title>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="font-weight-medium mb-0">
                                                        <span class="text-capitalize">{{NombreSeleccionado}}</span>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-chip v-if="archivo" class="mr-n3 pr-5" color="secondary" small dark close text-color="primary" @click:close="EliminarXLSX()">
                                                    <v-icon left size="15" color="primary">
                                                        mdi-microsoft-excel
                                                    </v-icon>
                                                    {{archivo_nombre}}
                                                </v-chip>
                                                <v-list-item-action class="mr-2" v-if="lista_seleccionados.length">
                                                    <v-btn icon class="secondary" @click="UploadXLSX()" :loading="upload_btn_loading">
                                                        <v-icon color="primary" size="20">mdi-file-upload-outline</v-icon>
                                                    </v-btn>
                                                    <v-text-field v-model="archivo_input" ref="xlsx_input" id="xlsx_input" type="file" @input="ReadXLSX()" hidden hide-details></v-text-field>
                                                </v-list-item-action >
                                                <v-list-item-action class="mr-2" v-if="lista_seleccionados.length">
                                                    <v-btn icon class="secondary" @click="GenerarXLSX()">
                                                        <v-icon color="primary" size="20">mdi-file-download-outline</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                                <v-list-item-action class="ma-0" v-if="lista_seleccionados.length">
                                                    <v-btn depressed rounded color="primary" @click="EnviarDatos()" :disabled="!BtnEnviar" :loading="!enviar_btn">Cargar Turnos</v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-toolbar>
                                    </template> 
                                    <v-card flat class="rounded-lg pt-5 px-3 pb-3" >
                                        <v-row>
                                            <v-col>
                                                 <v-card class="mx-auto" max-width="100%" flat outlined rounded="lg">
                                                    <v-list dense>
                                                    <v-list-item-group v-model="tipo_seleccionado" mandatory color="primary" >
                                                        <v-list-item value="ft">
                                                            <v-list-item-icon class="mr-3">
                                                                <v-icon size="20">mdi-account-group</v-icon>
                                                            </v-list-item-icon>                                                        
                                                            <v-list-item-content>
                                                                <v-list-item-title>Full Time</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                        <v-list-item value="pt">
                                                            <v-list-item-icon class="mr-3">
                                                                <v-icon size="20">mdi-account-group</v-icon>
                                                            </v-list-item-icon>                                                        
                                                            <v-list-item-content>
                                                                <v-list-item-title>Part-Time</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item><v-list-item value="il">
                                                            <v-list-item-icon class="mr-3">
                                                                <v-icon size="20">mdi-account-group</v-icon>
                                                            </v-list-item-icon>                                                        
                                                            <v-list-item-content>
                                                                <v-list-item-title>Inspector Linea</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                    </v-list>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-card outlined color="mx-3 rounded-lg">
                                                <v-date-picker v-model="fecha" type="month" no-title scrollable :max="FechaLimite" width="100%">
                                                </v-date-picker>
                                            </v-card>
                                        </v-row>
                                        <v-row>
                                            <v-col class="text-center">
                                                <v-btn class="mx-auto" depressed color="primary" @click="GenerarTabla()" :disabled="!BtnGenerar">Generar</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mt-1">
                            <v-col cols="12">
                                <v-card outlined rounded="0" >
                                    <v-overlay :opacity=".6" :color="TemaDark?'grey darken-4':'white'" :value="overlay.status" z-index="4" class="rounded-lg" absolute />
                                    <v-card v-if="overlay.status" class="loader-overlay d-flex justify-center align-center rounded-lg" flat height="0px" width="22vh">
                                        <v-row class="fill-height" align-content="center" justify="center">
                                            <v-col class="subtitle-1 text-center pa-0" cols="12">
                                                {{overlay.mensaje}}
                                            </v-col>
                                            <v-col cols="6 pt-0">
                                                <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-menu v-model="menu_edit.status" absolute offset-y rounded="lg" :close-on-content-click="false" max-width="100" transition="none" :close-on-click="false" v-if="lista_seleccionados.length" :open-on-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-simple-table dense fixed-header height="400">
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th class="text-left celda-nombre celda-limite celda_nombre celda-maq">
                                                                Maquinista
                                                            </th>
                                                            <th class="text-left" v-for="(fecha, index) in fechas_lista" :key="index" :class="DiaFeriado(fecha)">
                                                                {{fecha.slice(-2)}}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="maquinista in lista_seleccionados" :key="maquinista.id">
                                                            <td class="text-subtitle-2 text-capitalize grey--text text--darken-1 celda-nombre celda-limite pl-1">
                                                                <v-checkbox v-model="maquinista.habilitado" class="my-1 py-0" hide-details="" :ripple="false">
                                                                    <template v-slot:label>
                                                                        <span class="subtitle-2">{{maquinista.nombre}}</span>
                                                                    </template>
                                                                </v-checkbox>
                                                            </td>
                                                            <td v-ripple v-for="(turno, index) in maquinista.turnos" :key="index" class="text-center text-subtitle-2 text-uppercase py-0 px-0" :class="DiaFeriado(turno.fecha)" v-bind="attrs" v-on="on" @click="TablaDialog(maquinista.id, index)">
                                                                <div :class="CeldaEstado(maquinista.habilitado, turno.id, turno.fecha)">{{turno.turno}}</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </template>
                                        <v-card flat>
                                            <v-list>
                                                <v-list-item v-if="!menu_edit.disabled">
                                                    <v-text-field ref="turno_input" v-model="menu_edit.input" label="Turno" autofocus v-on:keyup.enter="TurnoCambio()" @focus="$event.target.select()" @input="menu_edit.input = menu_edit.input.toUpperCase()"/>
                                                </v-list-item>
                                            </v-list>
                                        </v-card>
                                    </v-menu>
                                    <v-card v-else height="400" flat class="justify-center align-center d-flex flex-column">
                                        <v-icon size="70" color="grey lighten-1">
                                            mdi-information-outline
                                        </v-icon>
                                        <span class="trenos-title-toolbar grey--text text--lighten-1">Genera una planilla</span>
                                        <span class="trenos-title-toolbar grey--text text--lighten-1" v-if="copia_local.datos">o</span>
                                        <v-btn depressed color="grey" dark v-if="copia_local.datos" @click="CargarLocal()">Cargar ultima planilla guardada</v-btn>
                                        <span class="caption grey--text text--lighten-1" v-if="copia_local.timestamp">{{copia_local.timestamp}}</span>
                                    </v-card>
                                </v-card>
                            </v-col>
                        </v-row>
                        <!--<v-row>
                            <v-col>
                                <v-data-table :headers="tabla_datos.header" :items="tabla_datos.datos" class="elevation-0" dense disable-filtering disable-pagination disable-sort hide-default-footer>
                                    <template v-slot:item.2021-12-01="props">
                                        <v-edit-dialog
                                        :return-value.sync="props.item.name"
                                        @save="save"
                                        @cancel="cancel"
                                        @open="open"
                                        @close="close"
                                        >
                                        {{ props.item.name }}
                                        <template v-slot:input>
                                            <v-text-field
                                            v-model="props.item.name"
                                            :rules="[max25chars]"
                                            label="Edit"
                                            single-line
                                            counter
                                            ></v-text-field>
                                        </template>
                                        </v-edit-dialog>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>-->
                    </v-container>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="CerrarLista()">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-fade-transition>
    </v-dialog>
</template>

<script>
import { mapState, mapMutations} from "vuex"   
import dayjs from "dayjs"
const XLSX = require('sheetjs-style')
//import XLSS from "sheetjs-style"
export default {
    name: 'TablaAsiganciones',
    data() {
        return {
            fechas_lista : [],
            turno_lista : [],
            nulo_lista : [],
            menu_mes :false,
            menu_picker: false,
            fecha: null,
            tipo_seleccionado: null,
            lista_seleccionados: [],
            turno_defecto : {},
            fecha_feriados: [],
            menu_edit: {
                status: false,
                input: null,
                maquinista_index: null,
                fecha_index: null,
                disabled: false
            },
            vista_generar : 0,
            copia_local : {},
            enviar_btn: true,
            archivo: null,
            archivo_nombre : null,
            archivo_input : null,
            overlay: {
                mensaje: null,
                status: false
            },
            turno_error: []
        }
    },
    computed: {
        ...mapState(['dialog', 'usuario','update','sistema']),
        DialogState(){
            return this.dialog.tabla_asignaciones
        },
        BtnGenerar(){
            let estado = false
            if(this.tipo_seleccionado && this.fecha) estado = true
            return estado
        },
        BtnEnviar(){
            let estado = false
            if(this.lista_seleccionados.length && (`${this.fecha}-${dayjs(this.fecha, 'YYYY-MM').daysInMonth()}` > dayjs().format('YYYY-MM-DD')) && !this.turno_error.length ) estado = true
            return estado
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        NombreTituloFecha(){
            let nombre_fecha = 'Generar planilla'
            if(this.fecha) nombre_fecha = dayjs(`${this.fecha}-01`).format('MMMM')+' '+this.fecha.substring(0, 4)
            return nombre_fecha
        },
        NombreFechaPicker(){
            let nombre_fecha = ''
            if(this.fecha){
                let fecha = dayjs(`${this.fecha}-01`).format('MMMM')+' '+this.fecha.substring(0, 4)
                nombre_fecha = fecha.charAt(0).toUpperCase() + fecha.slice(1)
            }
            return nombre_fecha
        },
        FechaLimite(){
            let fecha = dayjs().add(1, 'month')
            fecha = `${dayjs(fecha).format('YYYY-MM')}-${dayjs(fecha).daysInMonth()}`
            return fecha
        },
        NombreSeleccionado(){
            let nombre = ''
            switch (this.tipo_seleccionado) {
                case 'ft':
                    nombre = 'Full Time'
                    break
                case 'pt':
                    nombre = 'Part Time'
                    break
                default:
                    break
            }
            return nombre
        }
    },
    methods: {
        ...mapMutations(["ShowSnackBar", "ErrorRequest", 'CloseDialog','OpenDialog']),
        //Cierra el dialog actual (tabla asignaciones)
        CerrarLista() {
            this.CloseDialog('tabla_asignaciones')
        },
        //Llama a la lista de maquinistas, los filta segun el perfil seleccionado (PT/FT) y genera la tabla mensual.
        //Upload false: obtiene los datos de la api y los inserta en la tabla
        //Upload true: Obtiene los datos de la api pero solo guarda los turnos, fechas y otros (no asignaciones)
        async GenerarTabla(archivo){
            let fecha_axios,
                dias_mes
                //lista_maq = [],
                //tipo_maq
            if(this.fecha) fecha_axios = this.fecha.replace('-','')
            this.overlay = {
                mensaje: 'Obteniendo datos...',
                status : true
            }
            await this.axios.get(`/planilla/edit/asignacion/${fecha_axios}/${this.tipo_seleccionado}`, {headers: {token: this.usuario.token}})
                .then((respuesta) => {
                    if (respuesta.data.mensaje) this.ErrorRequest(respuesta.data.mensaje)
                    else {
                        this.lista_seleccionados =  respuesta.data.usuarios
                        this.turno_defecto =  respuesta.data.turno_defecto
                        this.fecha_feriados =  respuesta.data.fechas.feriados
                        this.turno_lista = respuesta.data.turnos.lista
                        this.nulo_lista = respuesta.data.fechas.nulos
                        this.turno_error = []
                    }
                    //Genera la cantidad de dias dentro del mes seleccionado.
                    if(!archivo){
                        this.archivo = null
                        this.archivo_nombre = null
                    }
                    this.fechas_lista = []
                    if(this.fecha) dias_mes = dayjs(`${this.fecha}-01`).daysInMonth() 
                    for (let index = 1; index <= dias_mes; index++) {
                        let dia_mes = index
                        if(dia_mes < 10) dia_mes = `0${dia_mes}`
                        this.fechas_lista.push(`${this.fecha}-${dia_mes}`)
                    }
                    this.menu_mes = null
                    this.overlay.status = false
                })
                .catch((err) => {
                    this.overlay = false
                    this.ErrorRequest(err)
                })
            
        },
        //Entrega el estado de la celda con base en ciertos parametros de ésta.
        CeldaEstado(habilitado, turno_id, fecha){
            let estado = 'celda-ok'
            if(habilitado && fecha > dayjs().format('YYYY-MM-DD')){
                if(turno_id) estado = 'celda-ok'
                else estado = 'celda-error'
            }else{
                estado = 'celda-disabled'
            }
            return estado
        },
        //Comprueba si el dia consultado es feriado , domingo o normal.
        DiaFeriado(fecha){
            let fecha_feriada = this.fecha_feriados.indexOf(fecha)
            if(fecha_feriada > -1 || dayjs(fecha).isoWeekday() == 7){
                if(dayjs(fecha).isoWeekday() == 7) return 'red--text celda-limite'
                else return 'red--text'
            }else{
                return 'grey--text text--darken-1'
            }
        },
        //Carga el turno de la celda seleccionada para que aparezcan en el menu (dialog).
        TablaDialog(maquinista_id, index){
            let maquinista_turno,
                maquinista_turno_index = this.lista_seleccionados.findIndex((maquinista) => {return maquinista.id == maquinista_id} )
            if(this.lista_seleccionados[maquinista_turno_index].habilitado){
                this.menu_edit.status = false
                this.$nextTick(() => { //Espera a que se cierre el menu (datos y DOM)
                    maquinista_turno = this.lista_seleccionados[maquinista_turno_index].turnos[index].turno
                    this.menu_edit.input = maquinista_turno
                    this.menu_edit.maquinista_index = maquinista_turno_index  
                    this.menu_edit.index = index
                    this.menu_edit.fecha_index = this.lista_seleccionados[maquinista_turno_index].turnos[index].fecha
                    let open_menu = (this.lista_seleccionados[maquinista_turno_index].habilitado && this.menu_edit.fecha_index > dayjs().format('YYYY-MM-DD')) ? true: false
                    if(open_menu){
                        this.menu_edit.status = true
                        this.$nextTick(() => {
                            if(this.menu_edit.status) this.$refs.turno_input.focus()
                        })
                    }
                })
            }else{
                this.menu_edit.status = false
            }
        },
        //Guarda el turno ingresado dentro de la fecha y maquinistas seleccionados.
        async TurnoCambio(){
            let turno_nombre = this.menu_edit.input,
                turno_fecha = this.lista_seleccionados[this.menu_edit.maquinista_index].turnos[this.menu_edit.index].fecha,
                maquinista_index = this.menu_edit.maquinista_index,
                fecha_index = this.menu_edit.index,
                turno_id = null
            this.lista_seleccionados[maquinista_index].turnos[fecha_index].turno = turno_nombre
            this.lista_seleccionados[maquinista_index].turnos[fecha_index].id = null
            this.menu_edit.input = null
            this.menu_edit.maquinista_index = null
            this.menu_edit.index = null
            this.menu_edit.status = false
            turno_id = this.TurnoFiltro(turno_nombre, turno_fecha)
            if(turno_id){
                this.lista_seleccionados[maquinista_index].turnos[fecha_index].id = turno_id.id
                let error_index = this.turno_error.findIndex( error => error.index == maquinista_index && error.fecha == turno_fecha )
                if(error_index > -1) this.turno_error.splice(error_index, 1)
                let app_data = JSON.parse(localStorage.getItem("app_data"))
                if(app_data.local && app_data.local.asignaciones) app_data.local.asignaciones = {
                    datos: this.lista_seleccionados,
                    timestamp: dayjs().format('DD-MM-YYYY HH:mm'),
                    feriados: this.fecha_feriados,
                    fechas: this.fechas_lista
                    }
                else app_data.local = {asignaciones :  {datos: this.lista_seleccionados,timestamp: dayjs().format('DD-MM-YYYY HH:mm')}}
                localStorage.setItem("app_data", JSON.stringify(app_data))
            }else{
                let error_index = this.turno_error.findIndex( error => error.index == maquinista_index && error.fecha == turno_fecha )
                if(error_index == -1) this.turno_error.push({index : maquinista_index, fecha: turno_fecha })
            }
        },
        TurnoFiltro(nombre, fecha){
            let dia_feriado = this.fecha_feriados.find(feriado => feriado == fecha),
                tipo_dia,
                turnos = JSON.parse(JSON.stringify(this.turno_lista)),
                turno,
                turno_filtro = [],
                nulos = JSON.parse(JSON.stringify(this.nulo_lista.lista))
            //se define el tipo de dia (laboral, sabado o domingo)
            if(!dia_feriado){
                tipo_dia = 'l'
                if(dayjs(fecha).isoWeekday() == 7) tipo_dia = 'd'
                if(dayjs(fecha).isoWeekday() == 6) tipo_dia = 's'
            }else{
                tipo_dia = 'd'
            }
            //Recorre la lista de turnos y filtra por nombre, tipo y fecha de validacion
            for (let index = 0; index < turnos.length; index++) { 
                let turno_fila = turnos[index]
                if(tipo_dia == 'l') {
                    if((turno_fila.tipo.includes('f') || turno_fila.tipo.includes('l') || turno_fila.tipo =='na') && turno_fila.fecha_inicio <= fecha && (!turno_fila.fecha_termino || turno_fila.fecha_termino >= fecha) && turno_fila.turno == nombre.toString().toLowerCase()){
                        let turno_nulo = nulos.filter(nulo => nulo.turno_id == turno_fila.id && nulo.fecha == fecha) //Verifica si el turno está anulado ese día
                        if(!turno_nulo.length) turno_filtro.push(turno_fila) //De estarlo lo ignora
                    }
                }else{
                    if((turno_fila.tipo.includes(tipo_dia) || turno_fila.tipo =='na') && turno_fila.fecha_inicio <= fecha && (!turno_fila.fecha_termino || turno_fila.fecha_termino >= fecha) && turno_fila.turno == nombre.toString().toLowerCase()){
                        let turno_nulo = nulos.filter(nulo => nulo.turno_id == turno_fila.id && nulo.fecha == fecha)
                        if(!turno_nulo.length) turno_filtro.push(turno_fila) 
                    }
                }
            }
            if(turno_filtro.length == 1){
                turno = turno_filtro[0]
            }else{
                turno = null
            }
            return turno
        },
        //Genera la estructura de datos y los envía a la API.
        async EnviarDatos(){
            let datos = {
                asignar: [],
                code : 1
            }
            let largo_lista = this.lista_seleccionados
            for (let maq = 0; maq < largo_lista.length; maq++) {
                if(this.lista_seleccionados[maq].habilitado){
                    let largo_mes = this.lista_seleccionados[maq].turnos.length
                    for (let fecha = 0; fecha < largo_mes; fecha++) {
                        if(this.lista_seleccionados[maq].turnos[fecha].fecha > dayjs().format('YYYY-MM-DD')){
                            let datos_turno = {
                                fecha : this.lista_seleccionados[maq].turnos[fecha].fecha,
                                turno: [{
                                    extra: 0,
                                    id: this.lista_seleccionados[maq].turnos[fecha].id
                                }],
                                usuario_id : this.lista_seleccionados[maq].id
                            }
                            datos.asignar.push(datos_turno)
                        }
                    }
                }
            }
            this.overlay = {
                mensaje: 'Enviando datos..',
                status: true
            }
            this.enviar_btn = false
            await this.axios.post(`/planilla/edit/`, datos, { headers: {token: this.usuario.token}
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                    if (respuesta.data.mensaje.status == 0) {
                        this.update.planilla = true
                        this.CloseDialog('tabla_asignaciones')
                    }
                }
                //this.listamaq = respuesta.data.lista
            }).catch(err => {
                this.btn_cargar_status = true
                this.ErrorRequest(err)
                this.overlay = {
                    mensaje: null,
                    status: false
                }
                this.enviar_btn = true
            })
        },
        //Obtiene si existe algun trabajo guardado en LocalStorage.
        ListaLocal(){
            let app_data = JSON.parse(localStorage.getItem("app_data"))
            if(app_data.local && app_data.local.asignaciones){
                this.copia_local = {
                    datos: app_data.local.asignaciones.datos,
                    timestamp: app_data.local.asignaciones.timestamp,
                    feriados: app_data.local.asignaciones.feriados,
                    fechas: app_data.local.asignaciones.fechas
                }
            }
        },
        //Carga los datos que están LocalStorage.
        CargarLocal(){
            this.lista_seleccionados =  this.copia_local.datos
            this.fecha_feriados =  this.copia_local.feriados
            this.fechas_lista =  this.copia_local.fechas
        },
        //Genera el documento .xlsx basado en la tabla generada.
        GenerarXLSX(){
            let fecha = this.tipo_seleccionado.toUpperCase() + ' ' + dayjs(`${this.fecha}-01`).format('MMMM') +' '+ this.fecha.substring(0, 4),
                nombre_libro = this.NombreCapital(fecha),
                wb = XLSX.utils.book_new(),
                nombre_archivo = `Asignaciones ${nombre_libro}.xlsx`
            wb.Props = {
                Title: "Cuadro de asignaciones",
                Subject: `${nombre_libro} ${this.tipo_seleccionado.toUpperCase()}`,
                Author: "Trenos",
                CreatedDate: new Date()
            }
            wb.SheetNames.push(nombre_libro)
            let libro_datos = [],
                libro_datos_mes = ['',`${this.tipo_seleccionado.toUpperCase()} ${this.fecha}`,`${this.NombreCapital(dayjs(`${this.fecha}-01`).format('MMMM'))}`] //Celda B1 formato tipo-fecha(YYYY-MM)
            //Relleno titulo mes (para darle estilo)
            for (let index = 0; index < this.fechas_lista.length - 1; index++) {
                libro_datos_mes.push('')
            }    
            libro_datos.push(libro_datos_mes)
            //Populacion segunda fila (fechas)
            let libro_titulos = ['Id' , 'Maquinista']
            for (let index = 0; index < this.fechas_lista.length; index++) {
                let fecha_col = this.fechas_lista[index].substring(this.fechas_lista[index].length - 2)
                libro_titulos.push(fecha_col)
            }
            libro_datos.push(libro_titulos)
            //Populacion nombres (primera columna)
            for (let i = 0; i < this.lista_seleccionados.length; i++) {
                let maq_info = this.lista_seleccionados[i],
                    maq_datos = [maq_info.id, this.NombreCapital(maq_info.nombre)]
                for (let j = 0; j < maq_info.turnos.length; j++) {
                    maq_datos.push(maq_info.turnos[j].turno)
                }  
                libro_datos.push(maq_datos)
            }
            //Definicion ancho columnas de la hoja (para cargar datos)
            let ws = XLSX.utils.aoa_to_sheet(libro_datos),
                ws_cols = [{ width: 0 }, { width: 20 }]
            for (let index = 0; index < this.fechas_lista.length; index++) {
                ws_cols.push({ width: 5 })
            }
            ws['!cols'] = ws_cols
            ws["!merges"] = [{s: { r: 0, c: 2 }, e: { r: 0, c: this.fechas_lista.length + 1 }}]
            //Populacion de datos y estilos
            for (let i = 0; i < (this.fechas_lista.length + 2); i++) { //Recorrido columnas
                for (let j = 0; j < (this.lista_seleccionados.length + 2); j++) { //Recorrido filas 
                    let estilo = { 
                        font: { name: 'Calibri', sz: 11,bold: true}, 
                        border: { 
                            right: {style: 'thin', color: '00000000'},
                            left: {style: 'thin', color: '00000000'},
                            top: {style: 'thin', color: '00000000'},
                            bottom: {style: 'thin', color: '00000000'}
                        }
                    }
                    //Fechas y titulo mes
                    if(j == 0){
                        if(i > 0){
                            estilo.font.sz = 18
                            estilo.alignment = { horizontal : 'center' }
                            estilo.border.bottom = { style: 'medium', color: '00000000'}
                            estilo.border.left = { style: 'medium', color: '00000000'}
                            estilo.border.right = { style: 'medium', color: '00000000'}
                        }
                    }
                    if(j == 1){ // Segunda fila (Titulos tabla)
                        if(i == 0) estilo.font.bold = false
                        if(i == 1)estilo.border.bottom = { style: 'medium', color: '00000000'}
                        if(i > 1){ //Fechas
                            let dia = ws[XLSX.utils.encode_cell({r:j, c:i})].v
                            if(this.fecha_feriados.includes(`${this.fecha}-${dia}`) || dayjs(`${this.fecha}-${dia}`).isoWeekday() == 7) estilo.font.color = {rgb : "FFF44336" } // Festivos o domingos
                            estilo.alignment = {horizontal:'center'}
                            if(dayjs(`${this.fecha}-${dia}`).isoWeekday() == 7) estilo.border.right = { style: 'medium', color: '00000000'} //Domingos
                            if(`${this.fecha}-${dia}` == `${this.fecha}-01`) estilo.border.left = { style: 'medium', color: '00000000'} //Primer Dia
                            if(dayjs(`${this.fecha}-${dia}`).daysInMonth() == dia) estilo.border.right = { style: 'medium', color: '00000000'}//Ultimo dia
                            estilo.border.bottom = { style: 'medium', color: '00000000'}
                        }
                    }
                    //Filas nombres y turnos
                    if(j > 1){
                        if(i == 1) estilo.border.right = { style: 'medium', color: '00000000'}
                        if(i > 1){ // Turnos por día
                            let dia = ws[XLSX.utils.encode_cell({r:1, c:i})].v
                            estilo.font.bold = false
                            estilo.alignment = { horizontal : 'center' }
                            if(this.fecha_feriados.includes(`${this.fecha}-${dia}`) || dayjs(`${this.fecha}-${dia}`).isoWeekday() == 7) estilo.font.color = {rgb : "FFF44336" }
                            if(dayjs(`${this.fecha}-${dia}`).isoWeekday() == 7) estilo.border.right = { style: 'medium', color: '00000000'} //Domingos
                            if(dayjs(`${this.fecha}-${dia}`).daysInMonth() == dia) estilo.border.right = { style: 'medium', color: '00000000'} //Ultimo dia
                        }
                        if(j == this.lista_seleccionados.length + 1) estilo.border.bottom = { style: 'medium', color: '00000000'}
                    }
                    if(ws[XLSX.utils.encode_cell({r:j, c:i})]) ws[XLSX.utils.encode_cell({r:j, c:i})].s = estilo
                }
            }
            wb.Sheets[nombre_libro] = ws
            XLSX.writeFile(wb, nombre_archivo)
        },
        //Genera los nombres de la tabla (capital).
        NombreCapital(nombre){
            const array = nombre.split(" ");
            for (var i = 0; i < array.length; i++) {
                array[i] = array[i].charAt(0).toUpperCase() + array[i].slice(1);            }
            const nombre_capital = array.join(" ");
            return nombre_capital
        },
        //Abre la interfaz para seleccionar un documento para subir.
        UploadXLSX(){
            let input_dom = document.getElementById('xlsx_input')
            if (input_dom != null) input_dom.click()
        },//Lee el documento seleccionado.
        async ReadXLSX(){
            let documento = document.getElementById('xlsx_input')
            this.archivo = documento.files[0]
            if(this.archivo) this.archivo_nombre = documento.files[0].name
            documento = documento.files[0]
            this.overlay = {
                mensaje: 'Cargando documento...',
                status: true
            }
            const documento_datos = await documento.arrayBuffer()
            let libro = XLSX.read(documento_datos, {type: 'array'})
            let hoja = libro.Sheets[libro.SheetNames[0]] //Selecciona la primera hoja
            let fecha_hoja = hoja[XLSX.utils.encode_cell({c: 1, r: 0})] // Obtiene la fecha de la celda
            if(fecha_hoja){
                let hoja_datos = fecha_hoja.v.split(' ')
                this.tipo_seleccionado = hoja_datos[0].toLowerCase()
                this.fecha = hoja_datos[1]
                await this.GenerarTabla(true)
            } 
            for (let j = 2; j < this.lista_seleccionados.length + 2; j++) { // Filas
                let turnos_mes = [],
                    maq_index
                for (let i = 0; i < this.fechas_lista.length + 2; i++) { // Columnas
                    let dato,
                        celda_referencia = XLSX.utils.encode_cell({c: i, r: j}),
                        celda = hoja[celda_referencia] 
                    if(celda.v) dato = celda.v
                    if(i == 0 & dato.length == 36){ //obtiene la id 
                        maq_index = this.lista_seleccionados.findIndex(function (maquinista) {return maquinista.id === dato})
                    }
                    if(i > 1){
                        let celda_fecha_referencia = XLSX.utils.encode_cell({c: i, r: 1}),
                            celda_fecha = hoja[celda_fecha_referencia],
                            turno_fecha = celda_fecha.v,
                            turno_id = this.TurnoFiltro(dato, `${this.fecha}-${turno_fecha}`)
                            if(!turno_id && `${this.fecha}-${turno_fecha}` > dayjs().format('YYYY-MM-DD')) this.turno_error.push({index : maq_index, fecha: `${this.fecha}-${turno_fecha}` })
                        if(turno_fecha){
                            let fecha_datos = {fecha: `${this.fecha}-${turno_fecha}`, turno: dato}
                            if(turno_id) fecha_datos.id = turno_id.id
                            turnos_mes.push(fecha_datos)
                        }
                    }
                    if(i == this.fechas_lista.length + 1){
                        this.lista_seleccionados[maq_index].turnos = turnos_mes
                    }
                }
            }
        },
        EliminarXLSX(){
            this.archivo = null
            this.archivo_nombre = null
            this.archivo_input = null
            this.ResetDatos()
        },
        ResetDatos(){
            this.fechas_lista = []
            this.turno_lista = []
            this.nulo_lista = []
            this.menu_mes = false
            this.menu_picker = false
            this.fecha =  null
            this.tipo_seleccionado = null
            this.lista_seleccionados = []
            this.turno_defecto = {}
            this.fecha_feriados = []
            this.menu_edit = {
                status: false,
                input: null,
                maquinista_index: null,
                fecha_index: null,
                disabled: false
            },
            this.vista_generar = 0
            this.copia_local = {}
            this.enviar_btn = true
            this.archivo = null
            this.archivo_nombre = null
            this.archivo_input = null
            this.overlay = {
                mensaje: null,
                status: false
            },
            this.turno_error = []
            this.ListaLocal()
        }
    },
    watch:{
        DialogState: function (val) {
            if (val == false) {
                this.ResetDatos()
            }
            
        }       
    }
}
</script>
<style scoped>
.celda-nombre{
    min-width: 220px !important;
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 3;
}
.celda-limite{
    border-right: thin solid rgba(0, 0, 0, 0.12);
}
.celda-maq{
    z-index: 4 !important;
}
.celda-error{
    background-color: var(--v-error-lighten5);
    height: 32px;
    line-height: 2rem;
}
.celda-ok{
    height: 32px;
    line-height: 2rem;
}

.loader-overlay {
    background-color: transparent;
    position: sticky;
    top: 300px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 4;
}
.celda-disabled{
    opacity: .2 !important;
}
</style>